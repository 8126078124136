<template>
  <div class="elems-container">
    <div
      v-for="(elem, index) in elements"
      :key="index"
      :class="[`elem elem-${color} text-bold`, {'selected': selectedElem === elem.value}]"
      @click="selectElem(elem.value)"
    >{{ $t(`supplier.${elem.name}`) }}</div>
  </div>
</template>



<script>
export default {
  name: 'ElemPicker',
  props: {
    /**
     * Picker elements
    */
    elements: {
      type: Array,
      default: () => []
    },
    /**
     * Selected element by default
    */
    selectedElement: {
      type: String,
      default: ''
    },
    /**
     * Can have unselected elements
    */
    canUnselect: {
      type: Boolean,
      default: false
    },
    /**
     * Picker color
    */
    color: {
      type: String,
      default: 'pink'
    },
  },
  data() {
    return {
      selectedElem: ''
    }
  },
  mounted() {
    this.selectedElem = this.selectedElement
  },
  methods: {
    selectElem(elem) {
      if (this.selectedElem !== elem) {
        this.selectedElem = elem
        this.$emit('add-query', this.selectedElem)
      } else if (this.canUnselect) {
        this.selectedElem = ''
        this.$emit('remove-query')
      }
    },

  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_colors";

.elems-container {
  width: 100%;
  display: flex;
  align-content: flex-start;
  border-radius: 12px;

  .elem {
    height: 30px;
    width: 100%;
    padding: 0 2em;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-right: 1px solid;

    display: flex;
    justify-content: center;
    align-items: center;

    &-pink {
      border-color: $pink;
      color: $pink;

      &.selected {
        background-color: $pink;
        color: white;
        opacity: 1 !important;
      }

      &:hover {
        cursor: pointer;
        background-color: $pink;
        color: white;
        opacity: 0.6;
      }
    }

    &-blue {
      border-color: $darkblue;
      color: $darkblue;

      &.selected {
        background-color: $darkblue;
        color: white;
        opacity: 1 !important;
      }

      &:hover {
        cursor: pointer;
        background-color: $darkblue;
        color: white;
        opacity: 0.6;
      }
    }

    &:first-child {
      border-left: 1px solid;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    &:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }

  }
}
</style>
