<template>
  <div class="mandat">
    <div class="previous" @click="$emit('previous')">
      <v-svg class="icon ml-2 mr-1" name="chevron-right" />
    </div>
    <div class="mandat-content">
      <Card>
        <div slot="header">
          <h3>{{ $t('supplier.rm.nego.title') }}</h3>
        </div>
        <div slot="body" class="content">
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierDepartmentCode') }}</span>
            <span>{{ mandat?.paymentSupplierDepartmentCode }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierDepartmentLabel') }}</span>
            <span>{{ mandat?.paymentSupplierDepartmentLabel }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierCnuf') }}</span>
            <span>{{ mandat?.paymentSupplierCnuf }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierLabel') }}</span>
            <span>{{ mandat?.paymentSupplierLabel }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierNegoGroupUid') }}</span>
            <span>{{ mandat?.paymentSupplierNegoGroupUid }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierNegoGroupLabel') }}</span>
            <span>{{ mandat?.paymentSupplierNegoGroupLabel }}</span>
          </div>
        </div>
      </Card>
      <Card>
        <div slot="header">
          <h3>{{ $t('supplier.rm.mandat.title') }}</h3>
        </div>
        <div slot="body">
          <div class="card-content">
            <div class="content">
              <div class="content-field">
                <span class="text-bold">{{ $t('supplier.rm.mandat.orderSupplierMarket') }}</span>
                <span>{{ mandat?.orderSupplierMarket }}</span>
              </div>
              <div class="content-field">
                <span class="text-bold">{{ $t('supplier.rm.mandat.orderSupplierCnuf') }}</span>
                <span>{{ mandat?.orderSupplierCnuf }}</span>
              </div>
              <div class="content-field">
                <span class="text-bold">{{ $t('supplier.rm.mandat.orderSupplierCode') }}</span>
                <span>{{ mandat?.orderSupplierCode }}</span>
              </div>
              <div class="content-field">
                <span class="text-bold">{{ $t('supplier.rm.mandat.orderSupplierLabel') }}</span>
                <span>{{ mandat?.orderSupplierLabel }}</span>
              </div>
              <div class="content-field">
                <span class="text-bold">{{ $t('supplier.rm.mandat.orderSupplierNegoGroupUid') }}</span>
                <span>{{ mandat?.orderSupplierNegoGroupUid }}</span>
              </div>
              <div class="content-field">
                <span class="text-bold">{{ $t('supplier.rm.mandat.orderSupplierNegoGroupLabel') }}</span>
                <span>{{ mandat?.orderSupplierNegoGroupLabel }}</span>
              </div>
            </div>

            <div class="line" />

            <template v-if="mandat">
              <div v-for="(rowMandat, index) in mandat.achAss" :key="rowMandat.type">
                <h4>{{ $t(`supplier.rm.mandat.${rowMandat.type}`) }}</h4>
                <ContactFormEmailAutoComplete
                  class="content"
                  :form-id="rowMandat.type"
                  :civility="rowMandat.title"
                  :user-i-d="rowMandat.matricule"
                  :last-name="rowMandat.lastName"
                  :first-name="rowMandat.firstName"
                  :email="rowMandat.emailAdress"
                  :disabled="rowMandat.write !== WRITE"
                  :user-search-disabled="rowMandat.write !== WRITE"
                  :phone="rowMandat.phoneNumber"
                  :to-be-checked="false"
                  :is-button-save="index === mandat.achAss.length - 1 && rowMandat.write === WRITE"
                  @update:saveForm="$emit('save', formSupplier)"
                  @update:contactFormEmailAutoComplete="formSupplier[rowMandat.type] = $event"
                />
              </div>
              <div class="line" />
              <div v-for="rowMandat in mandat.signataire" :key="rowMandat.typeLabel">
                <h4>{{ $t(`supplier.rm.mandat.${rowMandat.typeLabel}`) }}</h4>
                <ContactForm
                  class="content"
                  :form-id="rowMandat.typeLabel"
                  :civility="rowMandat.title"
                  :last-name="rowMandat.lastName"
                  :first-name="rowMandat.firstName"
                  :email="rowMandat.emailAdress"
                  :phone="rowMandat.phoneNumber"
                  :is-button-save="rowMandat.write === WRITE"
                  :disabled="rowMandat.write !== WRITE"
                  @update:contactform="saveSupplierMandat($event, rowMandat)"
                />
              </div>
            </template>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
  import Card from '@/components/Card.vue'
  import ContactForm from '@/components/ContactForm.vue'
  import ContactFormEmailAutoComplete from '@/components/ContactFormEmailAutoComplete.vue'
  import VSvg from '@/components/base/Svg'
  import { extendWithAuthorization, http } from '@/http'
  import { sendErrorToast, sendSuccessToast } from '@/utils/utils'
  import { mapState } from 'vuex'
  import { WRITE } from '@/utils/CONF'

  export default {
    name: 'SupplierRmMandatDetail',
    components: {
      Card,
      ContactForm,
      ContactFormEmailAutoComplete,
      VSvg
    },
    props: {
      mandat: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        formSupplier: {
          AcheteurMandat: {},
          mandatAssistanteNumber1: {}
        },
        WRITE
      }
    },
    computed: {
      ...mapState('user', ['campaignYear'])
    },
    methods: {
      async updateSupplierMandat(form, supplier) {
        try {
          this.$store.commit('common/SET_LOADING', true)
          const extendedHttp = await extendWithAuthorization(http)
          await extendedHttp
            .post(`api/supplier/rm/mandat`, {
              searchParams: {
                campaignYear: this.campaignYear,
                orderSupplierCnuf: this.mandat.orderSupplierCnuf,
                orderSupplierCode: this.mandat.orderSupplierCode,
                orderSupplierMarket: this.mandat.orderSupplierMarket,
                orderSupplierNegoGroupUid: this.mandat.orderSupplierNegoGroupUid,
                paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber,
                signatoryType: supplier.type,
                signatoryTitle: form.title ?? form.civility,
                signatoryLastName: form.lastName,
                signatoryFirstName: form.firstName,
                signatoryEmailAdress: form.emailAdress ?? form.email,
                signatoryPhoneNumber: form.phoneNumber ?? form.phone,
                updateMode: form.updateMode
              }
            })
            .json()
          sendSuccessToast(this.$t('message.success'))
        } catch (err) {
          sendErrorToast(this.$t('message.errorUpdateData'))
          this.$store.commit('common/SET_LOADING', false)
          console.log(err)
        }
      },
      async saveSupplierMandat(form, rowNego) {
        await this.updateSupplierMandat(form, rowNego)
        this.$emit('reload')
      }
    }
  }
</script>
<style lang="scss">
  .mandat {
    display: flex;
    height: 100%;
    &-button {
      margin-top: 1em;
      align-self: center;
    }
    &-content {
      flex: 1;
    }
    .card-content {
      display: flex;
      flex-direction: column;
    }
    .previous {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      rotate: 180deg;
      &:hover {
        background-color: $lightgrey;
        border-radius: 10px;
      }
    }
    .line {
      height: 1px;
      background: $lightgrey;
      align-self: center;
      width: 70%;
      margin: 1.4em 0;
    }
  }
</style>
