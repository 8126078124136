<template>
  <div class="main-container-appro">
    <template v-if="dataSupplierContract && perimeter">
      <div v-if="elements.length > 1" class="appro-picker">
        <ElemPicker :elements="elements" :selected-element="perimeter" color="blue" @add-query="perimeter = $event" />
      </div>
      <div class="container">
        <h3>{{ perimeter }}</h3>
        <template v-for="element in elements">
          <div v-for="(supplier, key) in dataSupplierContract[element.value].info" :key="`${element.value}-${key}`">
            <div v-show="element.value === perimeter">
              <div slot="header">
                <h3>{{ addSpaceInLabel(supplier.typeLabel) }}</h3>
              </div>
              <div slot="body" class="body-contract">
                <template v-if="supplier.type !== fournisseurSignLeaderType">
                  <ContactFormEmailAutoComplete
                    class="content"
                    :disabled="supplier.write !== WRITE"
                    :to-be-checked="false"
                    :user-search-disabled="supplier.write !== WRITE"
                    :form-id="supplier.typeLabel"
                    :civility="supplier.title"
                    :user-i-d="supplier.matricule"
                    :last-name="supplier.lastName"
                    :first-name="supplier.firstName"
                    :email="supplier.emailAdress"
                    :phone="supplier.phoneNumber"
                    :is-button-save="supplier.write === WRITE"
                    @update:saveForm="updateSupplierBlock($event, supplier, element.value)"
                  />
                </template>
                <template v-if="supplier.type === fournisseurSignLeaderType">
                  <ContactForm
                    class="content"
                    :disabled="supplier.write !== WRITE"
                    :user-search-disabled="supplier.write !== WRITE"
                    :form-id="supplier.typeLabel"
                    :civility="supplier.title"
                    :user-i-d="supplier.matricule"
                    :last-name="supplier.lastName"
                    :first-name="supplier.firstName"
                    :email="supplier.emailAdress"
                    :phone="supplier.phoneNumber"
                    :is-button-save="supplier.write === WRITE"
                    @update:contactform="updateSupplierBlock($event, supplier, element.value)"
                  />
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <div v-if="dataSupplierRubriques?.length > 0" class="container">
      <div slot="header" class="full-w">
        <h3>{{ $t('supplierDetail.listDepartementGdnCnuf') }}</h3>
      </div>
      <div slot="body" class="full-w">
        <div class="grid-rubriques">
          <span />
          <span class="text-bold">{{ $t('supplierDetail.perimeterLabel') }}</span>
          <span class="text-bold">{{ $t('supplierDetail.contractDepartment') }}</span>
          <span class="text-bold">{{ $t('supplierDetail.cnufContract') }}</span>
          <span class="text-bold">{{ $t('supplierDetail.supplierContractName') }}</span>
          <span class="text-bold">{{ $t('supplierDetail.gdnContract') }} </span>
          <span class="text-bold">{{ $t('supplierDetail.gdnContractName') }}</span>
        </div>
        <div v-for="(rubrique, key) in dataSupplierRubriques" :key="key">
          <div :class="{ odd: key % 2 === 0 }" class="grid-rubriques cursor-pointer" @click="rowSelected = rowSelected === key ? null : key">
            <span class="flex">{{ rowSelected === key ? '-' : '+' }}</span>
            <span>{{ rubrique.perimeterLabel }}</span>
            <span>{{ rubrique.paymentSupplierDepartmentCode }}</span>
            <span>{{ rubrique.paymentSupplierCnuf }}</span>
            <span>{{ rubrique.paymentSupplierLabel }}</span>
            <span>{{ rubrique.paymentSupplierNegoGroupUid }}</span>
            <span>{{ rubrique.paymentSupplierNegoGroupLabel }}</span>
          </div>
          <div v-show="rowSelected === key" class="sub-element">
            <h4>{{ addSpaceInLabel(rubrique.info[0].typeLabel) }}</h4>

            <div class="flex">
              <ContactFormEmailAutoComplete
                class="content"
                :disabled="rubrique.info[0].write !== WRITE"
                :to-be-checked="false"
                :user-search-disabled="rubrique.info[0].write !== WRITE"
                :form-id="rubrique.info[0].typeLabel"
                :civility="rubrique.info[0].title"
                :user-i-d="rubrique.info[0].matricule"
                :last-name="rubrique.info[0].lastName"
                :first-name="rubrique.info[0].firstName"
                :email="rubrique.info[0].emailAdress"
                :phone="rubrique.info[0].phoneNumber"
                :is-button-save="rubrique.info[0].write === WRITE"
                @update:saveForm="updateSupplierList($event, rubrique, rubrique.info[0].type)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import ContactFormEmailAutoComplete from '@/components/ContactFormEmailAutoComplete.vue'
  import ContactForm from '@/components/ContactForm.vue'
  import ElemPicker from '@/components/ElemPicker.vue'
  import { extendWithAuthorization, http } from '@/http'
  import supplier from '@/mixins/supplier'
  import { sendErrorToast } from '@/utils/utils'
  import { CONTRACT_DATA_APPRO, CONTRACT_LIST_APPRO, WRITE } from '@/utils/CONF'
  export default {
    name: 'SupplierAppro',
    components: {
      ContactFormEmailAutoComplete,
      ContactForm,
      ElemPicker
    },
    mixins: [supplier],
    data() {
      return {
        elements: [],
        rowSelected: null,
        dataSupplierRubriques: null,
        dataSupplierContract: null,
        CONTRACT_DATA_APPRO,
        CONTRACT_LIST_APPRO,
        fournisseurSignLeaderType: 3,
        perimeter: null,
        WRITE
      }
    },
    computed: {
      ...mapState('user', ['campaignYear', 'userMatriculeConnexion']),
      ...mapGetters('user', ['isAdmin'])
    },
    watch: {
      elements(elements) {
        //set default selected element to the first one available
        this.perimeter = elements[0].name
      }
    },
    async created() {
      this.$store.commit('common/SET_LOADING', true)
      await Promise.all([this.getApproContrat(), this.getApproRubriques()])
      this.$store.commit('common/SET_LOADING', false)
    },
    methods: {
      async getApproContrat() {
        try {
          const extendedHttp = await extendWithAuthorization(http)
          const res = await extendedHttp
            .get(`api/supplier/appro/contract`, {
              searchParams: {
                paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber,
                campaignYear: this.campaignYear,
                userMatriculeConnexion: this.isAdmin ? '' : this.userMatriculeConnexion
              }
            })
            .json()
          this.dataSupplierContract = []
          this.elements = []
          this.dataSupplierContract = res.reduce((resultat, element) => {
            const { perimeterLabel, ...otherProperties } = element
            this.elements.push({ name: perimeterLabel, value: perimeterLabel })
            resultat[perimeterLabel] = otherProperties
            return resultat
          }, {})
        } catch (err) {
          sendErrorToast(this.$t('message.error', { info: 'Données contrat' }))
          this.dataSupplierContract = null
        }
      },
      async getApproRubriques() {
        try {
          const extendedHttp = await extendWithAuthorization(http)
          this.dataSupplierRubriques = await extendedHttp
            .get(`api/supplier/appro/rubriques`, {
              searchParams: {
                paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber,
                campaignYear: this.campaignYear,
                userMatriculeConnexion: this.isAdmin ? '' : this.userMatriculeConnexion
              }
            })
            .json()
        } catch (err) {
          sendErrorToast(this.$t('message.error', { info: 'Rubriques' }))
          this.dataSupplierRubriques = null
        }
      },
      addSpaceInLabel(label) {
        return label?.replace(/([a-z])([A-Z])/g, '$1 $2')
      },
      async updateSupplierBlock(form, supplier, perimeterLabel) {
        await this.updateSupplier(form, supplier, perimeterLabel)
        await this.getApproContrat()
      }
    }
  }
</script>
<style lang="scss">
  @import '~@/styles/_colors';
  .appro-picker {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1em;
    .elems-container {
      width: 35%;
    }
  }
  .main-container-appro {
    .button {
      align-self: end;
    }
    .body-contract {
      display: flex;
    }
    .card-container {
      margin: 0.5em 0;
    }

    .content-referential-data {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .content {
      flex: 1;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(6, 16%);
      &-field {
        display: flex;
        flex-direction: column;
        line-height: 1.7em;
      }
    }

    .sub-element {
      margin: 0 40px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      position: relative;
    }

    .grid-rubriques {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 30px repeat(6, 15%);
      grid-template-rows: 40px;
      align-items: center;
    }

    .button-record {
      display: flex;
      flex-direction: row-reverse;
    }
    .odd {
      background-color: $background;
    }

    .even {
      background-color: $background;
    }

    .full-w {
      width: 100%;
    }

    .container {
      background-color: white;
      border-radius: 16px;
      padding: 1.5rem;
      margin-bottom: 1em;
      margin-top: 1em;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      border-radius: 4px;
      height: 2em;
      width: 2em;
      background: #fff;
      border: 2px solid $pink;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &:checked {
        &:before {
          content: '';
          // background: url('../../assets/svg/check.svg') no-repeat right center;
          height: 1.1em;
          width: 1.1em;
          font-size: 20px;
          position: absolute;
        }
      }
    }
  }
</style>
