<template>
  <div class="main-container-RM">
    <div>
      <div class="section" :class="{ slide: showNegoDetail || showMandatDetail || showContractDataDetail }">
        <section class="section1">
          <supplier-rm-contract-data
            v-if="dataSupplierContract.length"
            :supplier-contract-data="dataSupplierContract"
            @select-contract-data="selectContractData($event)"
          />
          <template v-if="dataSupplierNego.length > 0 || dataSupplierMandat.length > 0">
            <div class="nego-mandat">
              <ElemPicker
                v-if="dataSupplierNego.length > 0 && dataSupplierMandat.length > 0"
                :elements="elements"
                selected-element="Nego"
                color="blue"
                @add-query="onChange"
              />
            </div>
            <supplier-rm-nego v-if="showNego && dataSupplierNego.length > 0" :supplier-nego="dataSupplierNego" @select-nego="selectNego($event)" />
            <supplier-rm-mandat
              v-if="!showNego || (dataSupplierMandat.length > 0 && dataSupplierNego.length === 0)"
              :supplier-mandat="dataSupplierMandat"
              @select-mandat="selectMandat($event)"
            />
          </template>
        </section>
        <section v-if="showNegoDetail || showMandatDetail || showContractDataDetail" class="section2">
          <supplier-rm-contract-data-detail
            v-if="showContractDataDetail"
            :row-contract-data="rowContractData"
            @previous="showContractDataDetail = !showContractDataDetail"
            @reload="reloadContractDataDetail()"
          />
          <supplier-rm-nego-detail v-if="showNegoDetail" :nego="rowNego" @previous="showNegoDetail = !showNegoDetail" @reload="reloadNegoDetail($event)" />
          <supplier-rm-mandat-detail
            v-if="showMandatDetail"
            :mandat="rowMandat"
            @previous="showMandatDetail = !showMandatDetail"
            @save="updateSupplierMandator($event)"
            @reload="reloadMandatDetail()"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { extendWithAuthorization, http } from '@/http'
  import SupplierRmContractData from './SupplierRmContractData.vue'
  import SupplierRmContractDataDetail from './SupplierRmContractDataDetail.vue'
  import SupplierRmNego from './SupplierRmNego.vue'
  import SupplierRmNegoDetail from './SupplierRmNegoDetail.vue'
  import SupplierRmMandat from './SupplierRmMandat.vue'
  import SupplierRmMandatDetail from './SupplierRmMandatDetail.vue'
  import ElemPicker from '@/components/ElemPicker.vue'
  import { sendErrorToast, sendSuccessToast } from '@/utils/utils'
  import { CONTRACT_DATA_RM, CONTRACT_LIST_RM, REFRENCES_LIST_RM, WRITE } from '@/utils/CONF'

  export default {
    name: 'SupplierRm',
    components: {
      SupplierRmContractData,
      SupplierRmContractDataDetail,
      SupplierRmNego,
      SupplierRmNegoDetail,
      SupplierRmMandat,
      SupplierRmMandatDetail,
      ElemPicker
    },
    props: {
      rolesRights: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        dataSupplierContract: [],
        dataSupplierNego: [],
        dataSupplierMandat: [],
        elements: [
          { name: 'Nego', value: 'Nego' },
          { name: 'Mandat', value: 'Mandat' }
        ],
        showNego: true,
        showContractDataDetail: false,
        showNegoDetail: false,
        showMandatDetail: false,
        rowContractData: null,
        rowNego: null,
        rowMandat: null,
        CONTRACT_DATA_RM,
        CONTRACT_LIST_RM,
        REFRENCES_LIST_RM,
        WRITE,
        indexRowNego: null
      }
    },
    computed: {
      ...mapState('user', ['campaignYear', 'userMatriculeConnexion']),
      ...mapGetters('user', ['isAdmin'])
    },
    async created() {
      this.callApis()
    },
    methods: {
      async reloadContractDataDetail() {
        this.$store.commit('common/SET_LOADING', true)
        await this.getRmContrat()
        this.$store.commit('common/SET_LOADING', false)
      },
      async reloadNegoDetail() {
        this.$store.commit('common/SET_LOADING', true)
        await this.getRmNego()
        this.$store.commit('common/SET_LOADING', false)
      },
      async reloadMandatDetail() {
        this.$store.commit('common/SET_LOADING', true)
        await this.getRmMandat()
        this.$store.commit('common/SET_LOADING', false)
      },
      async callApis() {
        this.$store.commit('common/SET_LOADING', true)
        await Promise.all([this.getRmContrat(), this.getRmNego(), this.getRmMandat()])
        this.$store.commit('common/SET_LOADING', false)
      },
      async updateSupplierMandator(mandat) {
        this.$store.commit('common/SET_LOADING', true)
        try {
          const searchParams = {
            campaignYear: this.campaignYear,
            paymentSupplierDepartmentCode: this.rowMandat.paymentSupplierDepartmentCode,
            paymentSupplierCnuf: this.rowMandat.paymentSupplierCnuf,
            paymentSupplierNegoGroupUid: this.rowMandat.paymentSupplierNegoGroupUid,
            orderSupplierCode: this.rowMandat.orderSupplierCode,
            orderSupplierMarket: this.rowMandat.orderSupplierMarket,
            orderSupplierNegoGroupUid: this.rowMandat.orderSupplierNegoGroupUid,

            mandatBuyerNumber: mandat.AcheteurMandat.userID,
            mandatBuyerTitle: mandat.AcheteurMandat.civility,
            mandatBuyerLastName: mandat.AcheteurMandat.lastName,
            mandatBuyerFirstName: mandat.AcheteurMandat.firstName,
            mandatEmailAdress: mandat.AcheteurMandat.email,
            mandatPhoneNumber: mandat.AcheteurMandat.phone
          }

          for (let index = 1; index < this.rowMandat.achAss.length; index++) {
            //update rowMandat
            const assitantUpdated = mandat[this.rowMandat.achAss[index].type]
            if (assitantUpdated) {
              this.rowMandat.achAss[index].emailAdress = assitantUpdated.email ?? ''
              this.rowMandat.achAss[index].matricule = assitantUpdated.userID ?? ''
            }
            searchParams[`mandatEmailAdressAssistante${index}`] = this.rowMandat.achAss[index].emailAdress
            searchParams[`mandatAssistanteNumber${index}`] = this.rowMandat.achAss[index].matricule
          }

          const extendedHttp = await extendWithAuthorization(http)
          await extendedHttp
            .post(`api/supplier/mandator`, {
              searchParams
            })
            .json()
          sendSuccessToast(this.$t('message.success'))

          await this.reloadMandatDetail()
        } catch (err) {
          sendErrorToast(this.$t('message.errorUpdateData'))
          console.log(err)
        }
        this.$store.commit('common/SET_LOADING', false)
      },
      onChange(answer) {
        this.showNego = answer === 'Nego' ? true : false
      },
      async selectContractData(index) {
        this.rowContractData = null
        await this.reloadContractDataDetail()
        this.rowContractData = this.dataSupplierContract[index]
        this.showContractDataDetail = !this.showContractDataDetail
      },
      async selectNego(index) {
        await this.reloadNegoDetail()
        this.indexRowNego = index
        this.rowNego = this.dataSupplierNego[index]
        this.showNegoDetail = !this.showNegoDetail
      },

      async selectMandat(index) {
        this.rowMandat = null
        await this.reloadMandatDetail()
        this.rowMandat = this.dataSupplierMandat[index]
        this.showMandatDetail = !this.showMandatDetail
      },
      async getRmContrat() {
        try {
          const extendedHttp = await extendWithAuthorization(http)
          this.dataSupplierContract = await extendedHttp
            .get(`api/supplier/rm/contract`, {
              searchParams: {
                paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber,
                campaignYear: this.campaignYear,
                userMatriculeConnexion: this.isAdmin ? '' : this.userMatriculeConnexion
              }
            })
            .json()
        } catch (err) {
          sendErrorToast(this.$t('message.error', { info: 'Données contrat' }))
          this.dataSupplierContract = null
        }
      },
      async getRmNego() {
        this.rowNego = null
        try {
          const extendedHttp = await extendWithAuthorization(http)
          this.dataSupplierNego = await extendedHttp
            .get(`api/supplier/rm/nego`, {
              searchParams: {
                paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber,
                campaignYear: this.campaignYear,
                userMatriculeConnexion: this.isAdmin ? '' : this.userMatriculeConnexion
              }
            })
            .json()
          this.rowNego = this.indexRowNego >= 0 ?  this.dataSupplierNego[this.indexRowNego] : null
        } catch (err) {
          sendErrorToast(this.$t('message.error', { info: 'Nego' }))
          this.dataSupplierNego = []
        }
      },
      async getRmMandat() {
        try {
          const extendedHttp = await extendWithAuthorization(http)
          this.dataSupplierMandat = await extendedHttp
            .get(`api/supplier/rm/mandat`, {
              searchParams: {
                paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber,
                campaignYear: this.campaignYear,
                userMatriculeConnexion: this.isAdmin ? '' : this.userMatriculeConnexion
              }
            })
            .json()
        } catch (err) {
          sendErrorToast(this.$t('message.error', { info: 'Mandats' }))
          this.dataSupplierMandat = []
        }
      }
    }
  }
</script>
<style lang="scss">
  @import '~@/styles/_colors';

  .sticky {
    position: sticky;
    top: 0;
  }

  .nego-mandat {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1em;
    .elems-container {
      width: unset;
    }
  }

  .main-container-RM {
    .content-referential-data {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .content {
      display: grid;
      grid-gap: 16px;
      align-items: end;
      grid-template-columns: repeat(6, 16%);
      &-field {
        display: flex;
        flex-direction: column;
        line-height: 1.7em;
        align-self: start;
      }
    }
  }

  .section {
    display: flex;
    position: absolute;
    transition: 500ms;
    left: 0;
    &.slide {
      left: -100vw;
    }
    .section1 {
      width: 100vw;
      padding: 0 2em;
    }
    .section2 {
      max-height: 100vh;
      width: 100vw;
      padding: 0 2em;
    }
  }
</style>
