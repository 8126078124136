<template>
  <div>
    <Card>
      <div slot="body">
        <h4>{{ $t('supplier.rm.mandat.subTitle') }}</h4>
        <data-grid
        :columns="columns"
        :data-tab="supplierMandat"
        :limit-columns="6"
        isClickable
        @action-row="$emit('select-mandat', $event)" />
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card.vue'
import DataGrid from '@/components/DataGrid.vue'

export default {
  name: 'SupplierRmMandat',
  components: {
    Card,
    DataGrid
  },
  props: {
    supplierMandat:{
      type:Array,
      default:[]
    },
  },
  computed: {
    columns() {
      return this.supplierMandat.length > 0
        ? Object.keys(this.supplierMandat[0]).map(e => {
            return this.$t(`supplier.rm.mandat.${e}`)
          })
        : []    
      },
  }
}
</script>
<style lang="scss">

</style>
