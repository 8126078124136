<template>
  <div class="main-container">
    <div>
      <Card class="card-contract">
        <div slot="header">
          <h3>{{ $t('supplierDetail.contractData.title') }}</h3>
        </div>
        <div slot="body" class="content">
          <div class="content-field">
            <span class="text-bold">{{ $t('supplierDetail.contractData.paymentSupplierVATNumber') }}</span>
            <span>{{ dataSupplier?.paymentSupplierVATNumber }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplierDetail.contractData.paymentSupplierRCSNumber') }}</span>
            <span>{{ dataSupplier?.paymentSupplierRCSNumber }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplierDetail.contractData.socialReason') }}</span>
            <span>{{ dataSupplier?.paymentSupplierLabel }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplierDetail.contractData.paymentSupplierSocietyType') }}</span>
            <span>{{ dataSupplier?.paymentSupplierSocietyType }}</span>
          </div>
        </div>
      </Card>
      <ElemPicker class="picker" :elements="elements" :selected-element="selectedElement" :color="'blue'" @add-query="onChange" />
      <SupplierRm v-if="selectedElement==='R&M'" :roles-rights="dataSupplier?.typeMatricule" />
      <SupplierAppro v-if="selectedElement==='Appro'" :roles-rights="dataSupplier?.typeMatricule" />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { extendWithAuthorization, http } from '@/http'
  import ElemPicker from '@/components/ElemPicker.vue'
  import SupplierRm from '@/components/supplier/rm/SupplierRm.vue'
  import SupplierAppro from '@/components/supplier/appro/SupplierAppro.vue'
  import Card from '@/components/Card.vue'
  import { sendErrorToast } from '@/utils/utils'
  export default {
    name: 'Supplier',
    components: {
      ElemPicker,
      SupplierAppro,
      SupplierRm,
      Card
    },
    data() {
      return {
        dataSupplier: null,
        selectedElement: null,
        elements: [
          { name: 'R&M', value: 'R&M' },
          { name: 'Appro', value: 'Appro' }
        ],
        isAppr: false
      }
    },
    computed: {
      ...mapState('user', ['campaignYear', 'userMatriculeConnexion']),
      ...mapState('supplierDetail', ['supplierDetail']),
      ...mapGetters('user', ['isAdmin'])
    },
    watch: {
      campaignYear() {
        this.loadSupplierDetail()
      }
    },
    async created() {
      this.$store.commit('common/SET_LOADING', true)
      try {
        const extendedHttp = await extendWithAuthorization(http)
        this.dataSupplier = await extendedHttp
          .get(`api/supplier/contract`, {
            searchParams: {
              paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber,
              campaignYear: this.campaignYear,
              userMatriculeConnexion: this.isAdmin ? '' : this.userMatriculeConnexion
            }
          })
          .json()
      } catch (err) {
        sendErrorToast(this.$t('message.error', { info: 'Données contrat' }))
        this.dataSupplier = null
      }
      this.$store.commit('common/SET_LOADING', false)
    },
    methods: {
      onChange(answer) {
        this.selectedElement = answer
      }
    }
  }
</script>
<style lang="scss">
  .card-contract {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 1em;
  }
  .picker {
    margin: 1em 0;
  }
  .content {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, 25%);
    &-field {
      display: flex;
      flex-direction: column;
      line-height: 1.7em;
    }
  }
</style>
