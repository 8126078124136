import { extendWithAuthorization, http } from '@/http'
import { mapState } from 'vuex'
import { sendErrorToast, sendSuccessToast } from '@/utils/utils'

export default {
  computed: {
    ...mapState('user', ['campaignYear'])
  },
  methods: {
    async updateSupplier(form, supplier, perimeterLabel) {
      try {
        this.$store.commit('common/SET_LOADING', true)
        const extendedHttp = await extendWithAuthorization(http)
        await extendedHttp
          .post(`api/supplier`, {
            searchParams: {
              signatoryNumber: form.userID ?? '',
              signatoryTitle: form.title ?? form.civility,
              signatoryLastName: form.lastName,
              signatoryFirstName: form.firstName,
              signatoryEmailAdress: form.emailAdress ?? form.email,
              signatoryPhoneNumber: form.phoneNumber ?? form.phone,
              updateMode: form.updateMode,
              paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber,
              campaignYear: this.campaignYear,
              signatoryType: supplier.type,
              typeLabel: supplier.typeLabel,
              simulationPerimeter: perimeterLabel
            }
          })
          .json()
        sendSuccessToast(this.$t('message.success'))
      } catch (err) {
        sendErrorToast(this.$t('message.errorUpdateData'))
      } finally {
        this.$store.commit('common/SET_LOADING', false)
      }
    },
    async updateSupplierList(form, rubrique, signatoryType) {
      try {
        this.$store.commit('common/SET_LOADING', true)
        const extendedHttp = await extendWithAuthorization(http)
        await extendedHttp
          .post(`api/supplier/list`, {
            searchParams: {
              signatoryNumber: form.userID,
              signatoryTitle: form.civility,
              signatoryLastName: form.lastName,
              signatoryFirstName: form.firstName,
              signatoryEmailAdress: form.email,
              signatoryPhoneNumber: form.phone,
              updateMode: form.updateMode,
              paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber,
              campaignYear: this.campaignYear,
              signatoryType: signatoryType,
              paymentSupplierDepartmentCode: rubrique.paymentSupplierDepartmentCode,
              paymentSupplierCnuf: rubrique.paymentSupplierCnuf,
              paymentSupplierNegoGroupUid: rubrique.paymentSupplierNegoGroupUid,
              simulationPerimeter: rubrique.simulationPerimeter
            }
          })
          .json()
        sendSuccessToast(this.$t('message.success'))
      } catch (err) {
        sendErrorToast(this.$t('message.errorUpdateData'))
      } finally {
        this.$store.commit('common/SET_LOADING', false)
      }
    }
  }
}
