<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <header class="modal-header">
            <slot name="header" />
          </header>

          <section class="modal-body">
            <slot name="body" />
          </section>

          <footer class="modal-footer">
            <slot name="footer" />
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'VModal'
  }
</script>

<style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
    padding: 10px 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(grey, 0.5);
    transition: all 0.3s ease;
  }

  .modal-body {
    margin-bottom: 20px;
  }

  .modal-footer {
    display: flex;
    flex-direction: row-reverse;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
