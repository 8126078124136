<template>
  <div class="text-field">
    <div class="flex-start">
      <div v-if="type === 'tel' || type === 'telExt'" class="mr-1">
        <label for="inputField" class="text-field-label text-bold">{{ $t('dialingCode') }}</label>
      </div>
      <div class="mr-1">
        <label for="inputField" class="text-field-label text-bold">{{ label }}<span v-if="error.length" class="error">*</span></label>
      </div>
      <div>
        <v-chip v-if="error" :label="error" :icon="'warning'" :color="'error'" :is-small="true" />
      </div>
    </div>
    <div class="input-container">
      <div class="custom-dropdown">
        <input
          v-if="type === 'tel' || type === 'telExt'"
          ref="flagList"
          v-model="selectedCountry"
          class="search-country text-field-input"
          :disabled="disabled"
          @input="onCountryInput"
          @click="onCountryInput"
        />

        <div v-if="showDropdown" class="dropdown">
          <div v-for="(country, index) in filteredCountries" :key="index" @click="selectCountry(country.code)">
            <img :src="countryFlags[country.code]" :alt="country.name" @click="selectCountry(country.code)" />
            {{ country.name }}
          </div>
        </div>
      </div>

      <input
        :value="getValue"
        :type="type"
        :name="type"
        autocomplete="off"
        class="text-field-input"
        :class="error.length ? 'error' : ''"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="$emit('input', $event.target.value)"
        @paste="type === 'tel' ? onPaste($event) : null"
      />
    </div>
  </div>
</template>

<script>
  import VChip from '@/components/Chip'
  import {
    isValidPhoneNumber,
    parsePhoneNumberFromString,
    getCountries,
    getCountryCallingCode,
    isPossiblePhoneNumber,
    isValidNumberForRegion
  } from 'libphonenumber-js'
  import { COUNTRY_CODES } from '@/utils/country'

  export default {
    name: 'VTextField',
    components: {
      VChip
    },
    props: {
      /**
       * Input's label
       */
      label: {
        type: String,
        default: 'email'
      },
      /**
       * Input's type
       */
      type: {
        type: String,
        default: 'text'
      },
      /**
       * Input's placeholder
       */
      placeholder: {
        type: String,
        default: ''
      },
      /**
       * Error after submit
       */
      submitError: {
        type: String,
        default: ''
      },
      /**
       * input value
       */
      value: {
        type: String,
        default: ''
      },
      /**
       * disabled input yes or no
       */
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        validationError: '',
        phoneNumber: '',
        selectedCountry: '',
        countrySuggestions: getCountries(),
        countryFlags: {},
        showDropdown: false,
        filterText: ''
      }
    },
    computed: {
      filteredCountries() {
        return this.filterText
          ? COUNTRY_CODES.filter(country => country.name.toLowerCase().includes(this.filterText.toLocaleLowerCase()))
          : COUNTRY_CODES
      },
      error() {
        return this.validationError || this.submitError
      },
      getValue() {
        this.updateValue(this.value)
        return this.value
      },
      // eslint-disable-next-line vue/no-async-in-computed-properties
      async fetchCountryFlags() {
        const flags = {}
        for (const country of this.countrySuggestions) {
          try {
            // eslint-disable-next-line vue/no-async-in-computed-properties
            const { default: countryFlag } = await import(`country-flag-icons/3x2/${country}.svg`)

            flags[country] = countryFlag
          } catch (error) {
            console.error('Error loading country flag:', error)
            flags[country] = null
          }
        }

        return flags
      }
    },
    watch: {
      async selectedCountry() {
        this.countryFlags = await this.fetchCountryFlags
      }
    },
    beforeDestroy() {
      window.removeEventListener('click', this.handleWindowClick)
    },
    async mounted() {
      window.addEventListener('click', this.handleWindowClick)
      this.countryFlags = await this.fetchCountryFlags
      if ((this.type === 'telExt' || this.type === 'tel') && this.value) {
        let phone = parsePhoneNumberFromString(this.value, 'ZZ')
        this.selectedCountry = phone?.country
      }
    },
    methods: {
      handleWindowClick(event) {
        const element = this.$refs.flagList

        if (element && !element.contains(event.target)) {
          this.showDropdown = false
          if (!this.selectedCountry) {
            this.$emit('input', `${this.value ?? ''}`)
          }
        }
      },
      onCountryInput(event) {
        this.filterText = event.target.value
        this.showDropdown = true
      },
      selectCountry(country) {
        if (country !== this.selectedCountry) {
          if (this.value.includes('+')) {
            this.$emit('input', `+${getCountryCallingCode(country)}`)
          } else {
            this.$emit('input', `+${getCountryCallingCode(country)}${this.value ?? ''}`)
          }
        }

        this.selectedCountry = country
        this.showDropdown = false
      },
      findCountryByCode(targetCode) {
        return COUNTRY_CODES.find(country => country.code === targetCode)?.name ?? targetCode
      },
      validEmail(email) {
        let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        return email ? re.test(email) : true
      },
      validPhone(phone) {
        this.$emit('input', phone)
        let isValid = false

        if (phone) {
          if (this.selectedCountry) {
            const regExpFR = /^(\+33)([1-9]{1}\d{8})$/
            const ctrlFormat = this.selectedCountry === 'FR' ? regExpFR.test(phone) : true
            isValid =
              ctrlFormat &&
              isValidPhoneNumber(phone, this.selectedCountry) &&
              isPossiblePhoneNumber(phone, this.selectedCountry) &&
              isValidNumberForRegion(phone, this.selectedCountry)
          } else {
            isValid = isValidPhoneNumber(phone)
          }
        }
        return isValid
      },
      updateValue(value) {
        this.validationError = ''
        value = value === null || value === undefined ? '' : value

        if (!value && this.required) {
          this.validationError = this.$t('error.missing')
        } else if (this.type === 'email' && !this.validEmail(value)) {
          this.validationError = this.$t('error.email.invalid')
        } else if ((this.type === 'tel' || this.type === 'telExt') && value !== '') {
          if (!this.selectedCountry) {
            let phone = parsePhoneNumberFromString(this.value, 'ZZ')
            if (phone) {
              this.selectedCountry = phone?.country
            } else {
              this.validationError = this.$t('error.tel.countryCodeRequired')
            }
          } else {
            if (this.selectedCountry === 'FR') {
              value = value.replace(/^\+33(0)/, '+33')
            }
            if (!this.validPhone(value)) {
              this.validationError = this.$t('error.tel.invalid')
            }
          }
        }
        this.$emit('input', value)
        this.$emit('is-correct', !this.validationError.length)
      },
      onPaste(event) {
        event.preventDefault()
        const pastedText = this.value + (event.clipboardData || window.clipboardData)?.getData('text')?.trim()
        this.$emit('input', pastedText)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '~@/styles/_colors';
  .custom-dropdown {
    position: relative;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    max-height: 150px;
    overflow-y: scroll;
    z-index: 10;
  }

  .dropdown div {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
  }

  .dropdown img {
    margin-right: 10px;
    width: 20px; /* Ajustez la taille de l'image selon vos besoins */
    height: auto;
  }
  .search-country {
    max-width: 55px;
  }
  .input-container {
    display: flex;
    flex-direction: row;
    select {
      border: none;
    }
  }

  .text-field {
    .flex-start {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }

    &-input {
      height: 25px;
      width: -webkit-fill-available;
      font-size: 12px;
      border: 0;
      outline-width: 0;
      bottom: 0;
      color: $blue;
      background-color: transparent;

      &:disabled {
        color: $darkblue;
      }

      &::placeholder {
        color: $blue;
      }

      &:focus {
        border-bottom: 1px solid $blue;
        -webkit-transition: border 200ms ease-out;
        -moz-transition: border 200ms ease-out;
        -o-transition: border 200ms ease-out;
        transition: border 200ms ease-out;

        &::placeholder {
          color: transparent;
        }
      }

      &:not(:focus) {
        border-bottom: 1px solid $lightblue;
        -webkit-transition: border 200ms ease-in;
        -moz-transition: border 200ms ease-in;
        -o-transition: border 200ms ease-in;
        transition: border 200ms ease-in;
      }

      &.error {
        border-bottom: 1px solid $error;
        color: $blue !important;

        &::placeholder {
          color: $error;
        }

        &:focus::placeholder {
          color: transparent;
        }
      }
    }

    .error {
      color: $error;
    }

    &-label {
      color: $darkblue;
      font-size: 12px;
    }
  }

  .text-field-input:disabled {
    background-color: $lighterblue;
    padding-left: 5px;
    border-radius: 10px;
  }
</style>
