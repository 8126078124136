<template>
  <div>
    <autocomplete-email
      v-model="myForm.email"
      :is-loading="userSearchDisabled"
      :disabled="unchangeable || userSearchDisabled"
      :current-search="userSearch"
      :submit-error="validEmail(myForm.email) ? '' : $t('error.email.invalid')"
      :placeholder="$t('supplier.searchMail')"
      :items="supplierResults"
      @key-pressed="debounceSupplier"
      @selected="setSupplier"
      @is-correct="isForm.isEmail = $event"
    />
    <div class="contact-field">
      <div class="flex-justify-space">
        <label for="inputRadio">
          {{ $t('contactForm.civility') }}
          <span v-if="myForm.civility === null || myForm.civility === ''" class="error">*</span>
        </label>
        <div v-if="myForm.civility === null || myForm.civility === ''">
          <v-chip :label="$t('error.error')" :icon="'warning'" :color="'error'" :is-small="true" />
        </div>
      </div>
      <div class="contact-field-radio">
        <input
          :id="formId + 'mme'"
          v-model="myForm.civility"
          type="radio"
          :value="$t('contactForm.mme')"
          :disabled="disabled"
          class="cursor-pointer"
          @click="changedCivility"
        />
        <label :for="formId + 'mme'" class="cursor-pointer">{{ $t('contactForm.mme') }}</label>
        <input
          :id="formId + 'mr'"
          v-model="myForm.civility"
          type="radio"
          :value="$t('contactForm.mr')"
          :disabled="disabled"
          class="cursor-pointer"
          @click="changedCivility"
        />
        <label :for="formId + 'mr'" class="cursor-pointer">{{ $t('contactForm.mr') }}</label>
      </div>
    </div>
    <v-text-field
      v-model="myForm.lastName"
      class="contact-field"
      :label="$t('contactForm.lastName')"
      :type="'text'"
      :submit-error="myForm.lastName ? '' : $t('error.error')"
      :disabled="disabled"
      @is-correct="isForm.isLastName = $event"
    />
    <v-text-field
      v-model="myForm.firstName"
      class="contact-field"
      :label="$t('contactForm.firstName')"
      :type="'text'"
      :submit-error="myForm.firstName ? '' : $t('error.error')"
      :disabled="disabled"
      @is-correct="isForm.isFirstName = $event"
    />
    <v-text-field
      v-model="myForm.phone"
      class="contact-field"
      :label="$t('contactForm.phone')"
      :type="'tel'"
      :disabled="disabled"
      :required="false"
      @is-correct="isForm.isPhone = $event"
    />

    <div v-if="isButtonSave" class="buttons">
      <v-button
        :label="$t('button.record')"
        icon="record"
        color="pink"
        :disabled="!isValidForm"
        @action="myForm.updateMode ? (showSaveModal = !showModal) : $emit('update:contactform', myForm)"
      />
      <div class="delete">
        <v-svg name="trash" size="2em" @click="showModal = !showModal" />
      </div>
    </div>

    <v-modal v-if="showModal">
      <h3 slot="header" class="font-black">
        {{ $t('message.deletion') }}
      </h3>
      <span slot="body" class="font-black">{{ $t('message.deleteMessage') }}</span>
      <div slot="footer" class="buttons">
        <v-button color="darkblue" :label="$t('button.cancel')" @action="showModal = !showModal" />
        <v-button color="pink" :label="$t('button.delete')" @action="removeContact" />
      </div>
    </v-modal>
    <v-modal v-if="showSaveModal">
      <h3 slot="header" class="font-black">
        {{ $t('message.update') }}
      </h3>
      <span slot="body" class="font-black">{{ $t('message.updateMessage') }}</span>
      <div slot="footer" class="buttons">
        <v-button color="darkblue" :label="$t('button.cancel')" @action="showSaveModal = !showSaveModal" />
        <v-button color="pink" :label="$t('button.record')" @action="saveContact" />
      </div>
    </v-modal>
  </div>
</template>

<script>
  import VTextField from '@/components/TextField.vue'
  import VChip from '@/components/Chip'
  import VButton from '@/components/Button.vue'
  import VSvg from '@/components/base/Svg'
  import VModal from '@/components/base/Modal'
  import AutocompleteEmail from '@/components/AutocompleteEmail'
  import { extendWithAuthorization, http } from '@/http'
  import { sendErrorToast } from '@/utils/utils'
  export default {
    name: 'ContactForm',
    components: {
      VTextField,
      VChip,
      VButton,
      VSvg,
      VModal,
      AutocompleteEmail
    },
    props: {
      /**
       * the civility
       */
      formId: {
        type: String,
        default: ''
      },
      /**
       * the civility
       */
      civility: {
        type: String,
        default: ''
      },
      /**
       * the contact lastname
       */
      lastName: {
        type: String,
        default: ''
      },
      /**
       * the contact firstname
       */
      firstName: {
        type: String,
        default: ''
      },
      /**
       * the contact email
       */
      email: {
        type: String,
        default: ''
      },
      /**
       * the contact phone number
       */
      phone: {
        type: String,
        default: ''
      },
      /**
       * disabled field yes or no
       */
      disabled: {
        type: Boolean,
        default: false
      },
      isButtonSave: {
        type: Boolean,
        default: false
      },
      unchangeable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showModal: false,
        showSaveModal: false,
        userSearchDisabled: false,
        userSearch: this.email,
        supplierResults: [],
        dataSupplierInfoDetails: [],
        updateMode: true,
        isForm: {
          isCivility: !!this.civility,
          isLastName: false,
          isFirstName: false,
          isEmail: false,
          isPhone: true
        },
        myForm: {
          civility: this.civility,
          lastName: this.lastName,
          firstName: this.firstName,
          email: this.email,
          phone: this.phone,
          validForm: false,
          updateMode: true
        }
      }
    },
    computed: {
      isValidForm() {
        this.setEmailToForm(this.userSearch)
        this.setValidityCivility(this.myForm.civility)
        return (
          (!this.userSearchDisabled &&
            this.isForm.isCivility &&
            this.isForm.isLastName &&
            this.isForm.isFirstName &&
            this.validEmail(this.myForm.email) &&
            this.isForm.isPhone) ||
          (!this.myForm.civility &&
            !this.myForm.lastName &&
            !this.myForm.firstName &&
            !this.myForm.email &&
            !this.myForm.phone &&
            !this.userSearchDisabled)
        )
      }
    },
    methods: {
      removeContact() {
        this.userSearch = ''
        this.myForm = {
          civility: '',
          lastName: '',
          firstName: '',
          email: '',
          phone: '',
          validForm: true
        }
        this.showModal = !this.showModal
        this.$emit('update:contactform', this.myForm)
      },
      changedCivility(e) {
        if (this.myForm.civility === e.target.value) {
          this.myForm.civility = null
          this.isForm.isCivility = false
        } else {
          this.myForm.civility = e.target.value
          this.isForm.isCivility = true
        }
      },
      debounceSupplier(val) {
        this.userSearch = val
        clearTimeout(this.timoutID)
        if (val !== '' && val.length > 3) {
          this.timoutID = setTimeout(async () => {
            this.userSearchDisabled = true
            this.supplierResults = await this.getSupplierInfoDetailsByEmail(this.userSearch)
            this.myForm.updateMode = this.supplierResults.length ? true : false
            this.userSearchDisabled = false
          }, 1000)
        } else if (val === '') {
          this.setSupplier(null)
        }
      },
      setSupplier(value) {
        this.myForm.civility = value?.personalTitle ?? ''
        this.myForm.lastName = value?.iirBirthname ?? ''
        this.myForm.firstName = value?.givenName ?? ''
        this.myForm.phone = value?.iirDirectTelNr ?? ''
        this.myForm.userID = value?.uid ?? ''
        this.myForm.mail = value?.mail ?? ''
        this.myForm.email = value?.mail ?? ''
        this.userSearch = value?.mail ?? ''
      },
      async getSupplierInfoDetailsByEmail(emailValue) {
        try {
          const AUCHAN_EMAIL = 'auchan.'
          if (!emailValue.includes(AUCHAN_EMAIL)) {
            const extendedHttp = await extendWithAuthorization(http)
            return await extendedHttp
              .get(`api/supplier/infos`, {
                searchParams: {
                  email: emailValue,
                  paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber
                }
              })
              .json()
          } else {
            sendErrorToast(this.$t('message.invalidEmail'))
            return []
          }
        } catch (err) {
          sendErrorToast(this.$t('message.error', { info: 'Rubriques' }))
        }
      },
      validEmail(email) {
        if (this.unchangeable) {
          return true
        }
        let re = /^[\w.-]+@(?!.*auchan)[\w-]+(?:\.[\w-]+)?\.[a-zA-Z0-9]{2,10}$/
        return email ? re.test(email.toLowerCase()) : true
      },
      setValidityCivility(civility) {
        this.isForm.isCivility = !!civility
      },
      setEmailToForm(val) {
        this.myForm.email = val
      },
      saveContact() {
        this.showSaveModal = !this.showSaveModal
        this.$emit('update:contactform', this.myForm)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/_colors';
  .contact {
    &-field {
      display: flex;
      flex-direction: column;
      &-radio {
        height: 25px;
        display: flex;
        align-items: center;
      }
      span {
        font-weight: bold;
      }
      .flex-justify-space {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }
    }
  }
  .error {
    color: $error;
  }
  .buttons {
    display: flex;
    align-self: center;
    .delete {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: $pink;
      margin-left: 10px;
    }
  }
  .font-black {
    color: $black;
  }
</style>
