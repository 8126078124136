<template>
  <div>
    <Card>
      <div slot="body">
        <h4>{{ $t('supplier.rm.contractData.subTitle') }}</h4>
        <data-grid
          :columns="columns"
          :data-tab="rows"
          :limit-columns="7"
          is-clickable
          @action-row="$emit('select-contract-data', $event)"
        />
      </div>
    </Card>
  </div>
</template>

<script>
  import Card from '@/components/Card.vue'
  import DataGrid from '@/components/DataGrid.vue'

  export default {
    name: 'SupplierRmContractData',
    components: {
      Card,
      DataGrid
    },
    props: {
      supplierContractData: {
        type: Array,
        default: []
      }
    },
    computed: {
      rows() {
        return this.supplierContractData.map(achLead => {
          return {
            perimeterLabel: achLead.perimeterLabel,
            paymentSupplierCriteria: achLead.paymentSupplierCriteria,
            emailAdress: achLead.unchangeable[0].emailAdress,
            title: achLead.unchangeable[0].title,
            lastName: achLead.unchangeable[0].lastName,
            firstName: achLead.unchangeable[0].firstName,
            phoneNumber: achLead.unchangeable[0].phoneNumber
          }
        })
      },
      columns() {
        return this.supplierContractData.length > 0
          ? Object.keys(this.rows[0]).map(e => {
              return this.$t(`supplier.rm.contractData.${e}`)
            })
          : []
      }
    }
  }
</script>
<style lang="scss"></style>
