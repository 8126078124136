<template>
  <div>
    <autocomplete-email
      :disabled="disabled || userSearchDisabled"
      :isLoading="userSearchDisabled"
      :current-search="userSearch"
      :submit-error="myForm.email ? '' : $t('error.error')"
      :placeholder="'Rechercher un mail'"
      :items="buyerMandate"
      @key-pressed="debounceBuyerMandate"
      @selected="setBuyerMandate"
      @is-correct="isForm.isEmail = $event"
    />
    <div class="contact-field">
      <div class="flex-justify-space">
        <label for="inputRadio">
          {{ $t('contactForm.civility') }}
          <span v-if="myForm.civility === null || myForm.civility === ''" class="error">*</span>
        </label>
        <div v-if="myForm.civility === null || myForm.civility === ''">
          <v-chip :label="$t('error.error')" :icon="'warning'" :color="'error'" :is-small="true" />
        </div>
      </div>
      <div class="contact-field-radio">
        <input :id="formId + 'mme'" v-model="myForm.civility" type="radio" :value="$t('contactForm.mme')" :disabled="true" class="cursor-pointer" />
        <label :for="formId + 'mme'" class="cursor-pointer">{{ $t('contactForm.mme') }}</label>
        <input :id="formId + 'mr'" v-model="myForm.civility" type="radio" :value="$t('contactForm.mr')" :disabled="true" class="cursor-pointer" />
        <label :for="formId + 'mr'" class="cursor-pointer">{{ $t('contactForm.mr') }}</label>
      </div>
    </div>
    <v-text-field
      v-model="myForm.lastName"
      class="contact-field"
      :label="$t('contactForm.lastName')"
      :type="'text'"
      :submit-error="myForm.lastName ? '' : $t('error.error')"
      :placeholder="myForm.lastName ? '' : $t('error.missing')"
      :disabled="true"
      @is-correct="isForm.isLastName = $event"
    />
    <v-text-field
      v-model="myForm.firstName"
      class="contact-field"
      :label="$t('contactForm.firstName')"
      :type="'text'"
      :submit-error="myForm.firstName ? '' : $t('error.error')"
      :placeholder="myForm.firstName ? '' : $t('error.missing')"
      :disabled="true"
      @is-correct="isForm.isFirstName = $event"
    />
    <v-text-field class="contact-field" :label="$t('contactForm.phone')" :type="'tel'" :disabled="true" :required="false" />
    <v-button
      v-if="isButtonSave"
      :label="$t('button.record')"
      icon="record"
      color="pink"
      :disabled="!isValidForm"
      @action="$emit('update:saveForm', myForm)"
    />
  </div>
</template>

<script>
  import VTextField from '@/components/TextField.vue'
  import VChip from '@/components/Chip'
  import AutocompleteEmail from '@/components/AutocompleteEmail'
  import VButton from '@/components/Button.vue'
  import { mapGetters, mapState } from 'vuex'
  import { FEMALE_CIVILITIES, MADAME } from '@/utils/CONF'
  export default {
    name: 'ContactFormEmailAutoComplete',
    components: {
      VTextField,
      VChip,
      AutocompleteEmail,
      VButton
    },
    props: {
      /**
       * the civility
       */
      formId: {
        type: String,
        default: ''
      },
      /**
       * the civility
       */
      civility: {
        type: String,
        default: ''
      },
      /**
       * the contact lastname
       */
      lastName: {
        type: String,
        default: ''
      },
      /**
       * the contact firstname
       */
      firstName: {
        type: String,
        default: ''
      },
      /**
       * the contact userID
       */
      userID: {
        type: String,
        default: ''
      },
      /**
       * the contact email
       */
      email: {
        type: String,
        default: ''
      },
      /**
       * the contact phone number
       */
      phone: {
        type: String,
        default: ''
      },
      /**
       * disabled field yes or no
       */
      disabled: {
        type: Boolean,
        default: false
      },
      isButtonSave: {
        type: Boolean,
        default: false
      },
      // check form ?
      toBeChecked: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        userSearchDisabled: false,
        userSearch: this.email,
        buyerMandate: [],
        isForm: {
          isCivility: false,
          isLastName: false,
          isFirstName: false,
          isUserId: false,
          isEmail: true,
          isPhone: true
        },
        myForm: {
          civility: this.calculateCivility(this.civility),
          lastName: this.lastName,
          firstName: this.firstName,
          userID: this.userID,
          email: this.email,
          phone: this.phone,
          type: this.formId,
          validForm: false
        }
      }
    },
    computed: {
      ...mapState('user', ['foundUsers', 'campaignYear']),
      ...mapGetters('user', ['searchUsers']),
      isValidForm() {
        this.isForm.isCivility = !(this.myForm.civility === null || this.myForm.civility === '')
        return (
          !this.toBeChecked ||
          (this.isForm.isCivility && this.isForm.isLastName && this.isForm.isFirstName && this.isForm.isEmail && this.isForm.isPhone)
        )
      }
    },
    watch: {
      isForm: {
        handler(value) {
          this.myForm.validForm = this.isValidForm
          this.$emit('update:contactFormEmailAutoComplete', this.myForm)
        },
        deep: true
      }
    },
    methods: {
      debounceBuyerMandate(val) {
        this.userSearch = val
        clearTimeout(this.timoutID)
        if (val !== '' && val.length > 3) {
          this.timoutID = setTimeout(() => {
            this.userSearchDisabled = true
            this.$store.dispatch('user/searchUsers', { mails: this.userSearch }).then(() => {
              this.buyerMandate = this.foundUsers
              this.userSearchDisabled = false
            })
          }, 1000)
        } else if (val === '') {
          this.setBuyerMandate(null)
        }
      },
      setBuyerMandate(value) {
        const civility = value === null ? '' : value.personalTitle
        this.myForm.civility = this.calculateCivility(civility)
        this.myForm.lastName = value === null ? '' : value.iirBirthname
        this.myForm.firstName = value === null ? '' : value.givenName
        this.myForm.phone = value === null ? '' : value.iirDirectTelNr
        this.myForm.userID = value === null ? '' : value.uid
        this.myForm.mail = value === null ? '' : value.mail
        this.myForm.email = value === null ? '' : value.mail
        this.userSearch = value === null ? '' : value.mail
      },
      calculateCivility(value) {
        return FEMALE_CIVILITIES.includes(value?.toUpperCase()) ? MADAME : value
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/_colors';
  .contact {
    &-field {
      display: flex;
      flex-direction: column;
      line-height: 1.7em;
      &-radio {
        height: 25px;
        display: flex;
        align-items: center;
      }
      span {
        font-weight: bold;
      }
      .flex-justify-space {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }
    }
  }
  .error {
    color: $error;
  }
</style>
