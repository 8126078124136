import { render, staticRenderFns } from "./SupplierRmContractDataDetail.vue?vue&type=template&id=1cb41f30"
import script from "./SupplierRmContractDataDetail.vue?vue&type=script&lang=js"
export * from "./SupplierRmContractDataDetail.vue?vue&type=script&lang=js"
import style0 from "./SupplierRmContractDataDetail.vue?vue&type=style&index=0&id=1cb41f30&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports