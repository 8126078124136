<template>
  <div class="contract-data">
    <div class="previous" @click="$emit('previous')">
      <v-svg class="icon ml-2 mr-1" name="chevron-right" />
    </div>
    <div class="contract-data-content">
      <Card>
        <div slot="header">
          <h3>{{ $t('supplier.rm.contractData.titleBloc') }}</h3>
        </div>
        <div slot="body" class="content distribution">
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.contractData.perimeterLabel') }}</span>
            <span>{{ rowContractData?.perimeterLabel }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.contractData.paymentSupplierCriteria') }}</span>
            <span>{{ rowContractData?.paymentSupplierCriteria }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.contractData.emailAdress') }}</span>
            <span>{{ rowContractData?.unchangeable[0].emailAdress }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.contractData.title') }}</span>
            <span>{{ rowContractData?.unchangeable[0].title }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.contractData.lastName') }}</span>
            <span>{{ rowContractData?.unchangeable[0].lastName }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.contractData.firstName') }}</span>
            <span>{{ rowContractData?.unchangeable[0].firstName }}</span>
          </div>

          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.contractData.phoneNumber') }}</span>
            <span>{{ rowContractData?.unchangeable[0].phoneNumber }}</span>
          </div>
        </div>
      </Card>
      <Card>
        <div slot="body">
          <h4>{{ $t(`supplier.${rowContractData.editable.typeLabel}`) }}</h4>
          <div class="contract-update">
            <ContactForm
              class="content"
              :form-id="rowContractData.editable.typeLabel"
              :civility="rowContractData.editable.title"
              :last-name="rowContractData.editable.lastName"
              :first-name="rowContractData.editable.firstName"
              :email="rowContractData.editable.emailAdress"
              :phone="rowContractData.editable.phoneNumber"
              :is-button-save="rowContractData.editable.write === WRITE"
              :disabled="rowContractData.editable.write !== WRITE"
              @update:contactform="saveSupplierDistribution($event)"
            />
          </div>

          <div class="more-less">
            <span @click="showUnchangeableList = !showUnchangeableList">{{
              showUnchangeableList ? $t('supplier.seeLess') : $t('supplier.seeMore')
            }}</span>
          </div>

          <template v-if="showUnchangeableList">
            <div v-for="supplier in rowContractData.unchangeable" :key="supplier.type">
              <h4>{{ $t(`supplier.${supplier.type}`) }}</h4>
              <ContactForm
                class="content"
                :form-id="supplier.type"
                :civility="supplier.title"
                :last-name="supplier.lastName"
                :first-name="supplier.firstName"
                :email="supplier.emailAdress"
                :phone="supplier.phoneNumber"
                :disabled="true"
                :unchangeable="true"
              />
            </div>
          </template>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
  import Card from '@/components/Card.vue'
  import ContactForm from '@/components/ContactForm.vue'
  import VSvg from '@/components/base/Svg'
  import { extendWithAuthorization, http } from '@/http'
  import { sendErrorToast, sendSuccessToast } from '@/utils/utils'
  import { mapState } from 'vuex'
  import { WRITE } from '@/utils/CONF'

  export default {
    name: 'SupplierRmContractDataDetail',
    components: {
      Card,
      ContactForm,
      VSvg
    },
    props: {
      rowContractData: {
        type: Object,
        default: null
      },
      editableSupplier: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        showUnchangeableList: false,
        WRITE
      }
    },
    computed: {
      ...mapState('user', ['campaignYear'])
    },
    methods: {
      async updateSupplierDistribution(form) {
        try {
          this.$store.commit('common/SET_LOADING', true)
          const extendedHttp = await extendWithAuthorization(http)
          await extendedHttp
            .post(`api/supplier/rm/distribution`, {
              searchParams: {
                campaignYear: this.campaignYear,
                paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber,
                simulationPerimeter: this.rowContractData.perimeterLabel,
                paymentSupplierCriteria: this.rowContractData.paymentSupplierCriteria,
                signatoryType: this.rowContractData.editable.type,
                signatoryTitle: form.title ?? form.civility,
                signatoryLastName: form.lastName,
                signatoryFirstName: form.firstName,
                signatoryEmailAdress: form.emailAdress ?? form.email,
                signatoryPhoneNumber: form.phoneNumber ?? form.phone,
                updateMode: form.updateMode
              }
            })
            .json()
          sendSuccessToast(this.$t('message.success'))
          this.$emit('reload')
        } catch (err) {
          sendErrorToast(this.$t('message.errorUpdateData'))
          this.$store.commit('common/SET_LOADING', false)
          console.log(err)
        }
      },
      async saveSupplierDistribution(form) {
        await this.updateSupplierDistribution(form)
        this.$emit('reload')
      }
    }
  }
</script>
<style lang="scss">
  .more-less {
    text-align: center;
    span {
      cursor: pointer;
      color: $pink;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .contract-data {
    display: flex;
    height: 100%;
    &-button {
      margin-top: 1em;
      align-self: center;
    }
    &-content {
      flex: 1;
      .distribution {
        grid-template-columns: repeat(7, 13%) !important;
      }
    }
    .card-content {
      display: flex;
      flex-direction: column;
    }
    .previous {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      rotate: 180deg;
      &:hover {
        background-color: $lightgrey;
        border-radius: 10px;
      }
    }
    .line {
      height: 1px;
      background: $lightgrey;
      align-self: center;
      width: 70%;
      margin: 1.4em 0;
    }
  }
</style>
