<template>
  <div>
    <Card>
      <div slot="body">
        <h4>{{ $t('supplier.rm.nego.subTitle') }}</h4>
        <data-grid
        :columns="columns"
        :data-tab="supplierNego"
        :limit-columns="7"
        isClickable
        @action-row="$emit('select-nego', $event)" />
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card.vue'
import DataGrid from '@/components/DataGrid.vue'

export default {
  name: 'SupplierRmNego',
  components: {
    Card,
    DataGrid
  },
  props: {
    supplierNego:{
      type:Array,
      default:[]
    },
  },
  computed: {
    columns() {
      return this.supplierNego.length > 0
        ? Object.keys(this.supplierNego[0]).map(e => {
            return this.$t(`supplier.rm.nego.${e}`)
          })
        : []
    },
  }
}
</script>
<style lang="scss">

</style>
