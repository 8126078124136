<template>
  <div class="nego">
    <div class="previous" @click="$emit('previous')">
      <v-svg class="icon ml-2 mr-1" name="chevron-right" />
    </div>
    <div class="nego-content">
      <Card>
        <div slot="header">
          <h3>{{ $t('supplier.rm.nego.title') }}</h3>
        </div>
        <div slot="body" class="content">
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierDepartmentCode') }}</span>
            <span>{{ nego?.paymentSupplierDepartmentCode }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierDepartmentLabel') }}</span>
            <span>{{ nego?.paymentSupplierDepartmentLabel }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierCnuf') }}</span>
            <span>{{ nego?.paymentSupplierCnuf }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierLabel') }}</span>
            <span>{{ nego?.paymentSupplierLabel }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierNegoGroupUid') }}</span>
            <span>{{ nego?.paymentSupplierNegoGroupUid }}</span>
          </div>
          <div class="content-field">
            <span class="text-bold">{{ $t('supplier.rm.nego.paymentSupplierNegoGroupLabel') }}</span>
            <span>{{ nego?.paymentSupplierNegoGroupLabel }}</span>
          </div>
        </div>
      </Card>
      <Card>
        <div slot="body">
          <template v-if="nego">
            <div v-for="rowNego in nego.editable" :key="rowNego.typeLabel">
              <h4>{{ $t(`supplier.rm.nego.${rowNego.typeLabel}`) }}</h4>
              <ContactForm
                class="content"
                :form-id="rowNego.typeLabel"
                :civility="rowNego.title"
                :last-name="rowNego.lastName"
                :first-name="rowNego.firstName"
                :email="rowNego.emailAdress"
                :phone="rowNego.phoneNumber"
                :is-button-save="rowNego.write === WRITE"
                :disabled="rowNego.write !== WRITE"
                @update:contactform="saveSupplierList($event, rowNego)"
              />
            </div>
            <div class="more-less">
              <span @click="showUnchangeableList = !showUnchangeableList">{{
                showUnchangeableList ? $t('supplier.seeLess') : $t('supplier.seeMore')
              }}</span>
            </div>
            <template v-if="showUnchangeableList">
              <div v-for="rowNego in nego.unchangeable" :key="rowNego.type">
                <h4>{{ $t(`supplier.rm.nego.${rowNego.type}`) }}</h4>
                <ContactForm
                  class="content"
                  :form-id="rowNego.type"
                  :civility="rowNego.title"
                  :last-name="rowNego.lastName"
                  :first-name="rowNego.firstName"
                  :email="rowNego.emailAdress"
                  :phone="rowNego.phoneNumber"
                  disabled
                  :unchangeable="true"
                />
              </div>
            </template>
          </template>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
  import Card from '@/components/Card.vue'
  import ContactForm from '@/components/ContactForm.vue'
  import VSvg from '@/components/base/Svg'
  import supplier from '@/mixins/supplier'
  import { extendWithAuthorization, http } from '@/http'
  import { sendErrorToast, sendSuccessToast } from '@/utils/utils'
  import { mapState } from 'vuex'
  import { WRITE } from '@/utils/CONF'

  export default {
    name: 'SupplierRmNegoDetail',
    components: {
      Card,
      ContactForm,
      VSvg
    },
    mixins: [supplier],
    props: {
      nego: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        showUnchangeableList: false,
        WRITE
      }
    },
    computed: {
      ...mapState('user', ['campaignYear'])
    },
    methods: {
      async updateSupplierList(form, rowNego) {
        try {
          this.$store.commit('common/SET_LOADING', true)
          const extendedHttp = await extendWithAuthorization(http)
          await extendedHttp
            .post(`api/supplier/list`, {
              searchParams: {
                signatoryNumber: rowNego.matricule,
                signatoryTitle: form.title ?? form.civility,
                signatoryLastName: form.lastName,
                signatoryFirstName: form.firstName,
                signatoryEmailAdress: form.emailAdress ?? form.email,
                signatoryPhoneNumber: form.phoneNumber ?? form.phone,
                updateMode: form.updateMode,
                paymentSupplierVATNumber: this.$route.query.paymentSupplierVATNumber,
                campaignYear: this.campaignYear,
                signatoryType: rowNego.type,
                paymentSupplierDepartmentCode: this.nego.paymentSupplierDepartmentCode,
                paymentSupplierCnuf: this.nego.paymentSupplierCnuf,
                paymentSupplierNegoGroupUid: this.nego.paymentSupplierNegoGroupUid
              }
            })
            .json()
          sendSuccessToast(this.$t('message.success'))
        } catch (err) {
          sendErrorToast(this.$t('message.errorUpdateData'))
          this.$store.commit('common/SET_LOADING', false)
          console.log(err)
        }
      },
      async saveSupplierList(form, rowNego) {
        await this.updateSupplierList(form, rowNego)
        this.$emit('reload')
      }
    }
  }
</script>
<style lang="scss">
  .nego {
    display: flex;
    align-items: center;
    height: 100%;
    &-content {
      flex: 1;
    }
    .previous {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      rotate: 180deg;
      &:hover {
        background-color: $lightgrey;
        border-radius: 10px;
      }
    }
  }
</style>
